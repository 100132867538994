import itemMixins from "@/components/page/indexItem.js";
export default {
  mixins: [itemMixins],
  computed: {
    isMedia() {
      return this.item.is_media
    },
    detailName() {
      if(['blog-index', 'blog-detail'].includes(this.$route.name)) return 'blog-detail'
      return this.isMedia ? "media-detail" : "blog-detail";
    },
    to() {
      if (!this.item) return "";
      return {
        name: this.detailName,
        params: {
          site_id: this.site_id,
          code: this.code,
        },
      };
    },
  },
  methods: {
    goDetail() {
      this.$router.push(this.to)
    },
  },
};
