<template>
  <div class="blog-smallItem mb-5 mb-md-5">
    <div class="blog-smallItem_imgBox">
      <v-img
        @click="goDetail"
        cover
        class="blog-smallItem_imgBox_img pointer"
        width="100%"
        aspect-ratio="1.777"
        :src="photoUrl"
      ></v-img>
    </div>
    <div class="blog-smallItem_textBox d-flex flex-column px-2">
      <div class="mb-4 mb-md-0">
        <div class="d-flex mt-4">
          <p class="blog-smallItem_textBox_date mr-3">{{ created_at }}</p>
          <p class="blog-smallItem_textBox_tag">{{ category_name }}</p>
        </div>
        <h1 class="blog-smallItem_textBox_title text-overflow-ep-2">
          {{ name }}
        </h1>
        <div
          v-html="content"
          class="blog-smallItem_textBox_content text-overflow-ep-3 text-overflow-ep-md-2 html-editor"
        ></div>
      </div>
      <div class="d-flex justify-end mt-auto mt-md-5 mr-0 mr-md-2">
        <TypeB-btn :to="to">{{ $t("action.more") }}</TypeB-btn>
      </div>
    </div>
  </div>
</template>

<script>
import item from "@/components/blog/item.js";
export default {
  mixins: [item],
};
</script>